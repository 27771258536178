import React from 'react'

import PageHeader from '../components/PageHeader'
import DelConditions from '../components/DelConditions'

import {en, es} from '../languages/langs'

import {supplierConditions,esSupplierConditions} from '../data/deliveryConditions'

const SupplierConditions = ({activeLang}) => {
	const lang = {en, es}[activeLang]

    const supplierRules = activeLang === 'en' ? supplierConditions : esSupplierConditions




	return (
		<React.Fragment>
			<PageHeader titleKey="Sup Rules" image="images/papegaai.jpg" bgPos="0% 50%" activeLang={activeLang} />		
			<DelConditions conditions={supplierRules}/>
		</React.Fragment>
	)
}

export default SupplierConditions