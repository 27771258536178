export const en = {
	"Home": "Home",
	"FAQ": "FAQ",
	"Faq": "Faq",
	"Upload": "Upload",
	"Your inquiry": "Your inquiry",
	"Inquiries to apply": "Inquiries to apply",
	"Login": "Login",
	"Sorry": "Sorry",
	"Please": "Please ",
//	"Note that": "Note that you need to be registered to have access to our inventory list.",
	"Note that": "Note that we regularly send list of species that are available, as well that are required. Our services are from zoo to zoo. We also have mailings about specialized subjects. If you want to be informed regularly, please register yourself.",
	"You need to be registered to access the complete surplus list": "You need to be registered to access the complete surplus list",
	"If you are not registered, register":"If you are not registered, register",
	"here": "here",
	"If you are registered please": "If you are registered please ",
	"log in": "log in",
	"Register": "Register",
	"Surplus/Wanted lists": "Surplus/Wanted lists",
	"Since 1985": `Since 1985 the consultancy<br />service for zoos worldwide.`,
	"A worldwide network": "A worldwide network",
	"See all": "See all",
	"Species to relocate and special projects": "Species to relocate and special projects",
	"RELOCATION OF A LARGE VARIETY OF SPECIES":"RELOCATION OF A LARGE VARIETY OF SPECIES",
	"Contacts with over 15000 institutions": "Contacts with over 15000 institutions",
	"izs_intro": `International Zoo Services is a consultancy group
		for zoological collections worldwide, specialized
		in the relocation of animals. It was founded in 1985
		y John Rens, former deputy-director of Wassenaar Zoo,
		The Netherlands. This zoo was famous for their breeding
		results with bonobos, gorillas, cheetahs, and birds of prey.`,
	"readmore": 'Read more',
	"Transport directly from zoo to zoo": "Transport directly from zoo to zoo",
	"Live animal transport coordinations": "Live animal transport coordinations",
	"Space for a sub-title": "Space for a sub-title",
	"izs_intro_2": `International Zoo Services is a consultancy group
		for zoological collections worldwide, specialized
		in the relocation of animals. It was founded in 1985
		y John Rens, former deputy-director of Wassenaar Zoo,
		The Netherlands. This zoo was famous for their breeding
		results with bonobos, gorillas, cheetahs, and birds of prey.`,
	"Contact us": "Contact us",
	"Our worldwide network": `Our worldwide network can assist you
		to locate your wanted species and find new homes for your
		surplus-specimens.`,
	"Our clients are": "Our clients are",
	"Zoos & private breeding centers": "Zoos & private breeding centers",
	"ourclientsare_text": `Mostly zoological parks and aquariums from all over the world,
		open to the public for educational purposes, as well as recognized breeding centers
		that manage their animal-collection according to current approved standards.`,
	"Penguins": "Penguins",
	"lorem": `Lorem ipsum dolor sit amet consectetur adipisicing elit.
				Pariatur consequuntur provident blanditiis.`,
	"About us": "About us",
	"Del Rules": "Our Delivery Conditions",	
	"Sup Rules":"Suppliers Conditions",
	"Stuffed Animals": "Stuffed Animals",
	"about_us_intro": `<span>International Zoo Services is a consultancy group
		for zoological collections worldwide, specialized in the relocation
		of animals. It was founded in 1985 by John Rens, former deputy-director
		of Wassenaar Zoo, The Netherlands. This zoo was famous for their
		breeding results with bonobos, gorillas, cheetahs, and birds of prey.</span>
		<br /><br />
		<span>Our organization is coordinating the exchanges as well the
		transportation of animals to zoos worldwide; our team is
		maintaining a databank of thousands of zoos. This worldwide
		network can assist you collecting the suitable information for
		realizing your breeding programs.</span>`,
	"Founder of International Zoo Services": "Founder of International Zoo Services",
	"Our services": "Our services",
	"Our projects": "Our projects",
	"ourprojects_subtitle_home": `The best way to illustrate the work of International Zoo Services`,
	"ourprojects_subtitle": `The best way to illustrate the work of International Zoo Services
		is perhaps to present you with a survey of some of the favourite
		projects that have been completed over the past years. See here below a selection`,
	"project_Penguins": `A large group of penguins, including King penguins, 
		Rock hopper-penguins, Magelhaen-peguins and Gentoo-penguins
		were located in South-America and transported to a new zoo
		in Spain.`,
	"Giraffes": "Giraffes",
	"project_Giraffes": `A pair of giraffes was transported from Europe to
		Brazil, needless to say, a highly complicated project.`,
	"King-cheetahs": "King-cheetahs",
	"project_King-cheetahs" : `A pair of rare King-cheetahs left Africa under
		our care to their new home in a special breeding-facility
		in the Middle East.`,
	"Giant tortoises": "Giant tortoises",
	"project_Giant tortoises": `Giant tortoises are very attractive to the public.
		A large group of these huge reptiles from a special breeding
		-centre were delivered to several zoos in Europe and Asia.`,
	"Asian Elephants": "Asian Elephants",
	"project_Asian Elephants": `Several Asian elephants accompanied by some Mahouts
		were imported into Germany, coming from different South-East
		Asian countries.`,
	"Gang-gang cockatoos": "Gang-gang cockatoos",
	"project_Gang-gang cockatoos": `A pair of Gang-gang cockatoos were located, a highly
		desired and subsequently rarely offered species.`,
	"Bongo": "Bongo",
	"project_Bongo": `One of our first projects took place during the eighties
		when Eastern and Western Europe were still separated. We managed
		the transfer of a male Bongo from Czechoslovakia to a zoo in West
		Germany where it participated in a very successful breeding program.`,
	"Clouded leopards": "Clouded leopards",
	"project_Clouded leopards": `A pair of clouded leopards was sent from a private breeding-
		center in the U.S.A to a zoo in Tokyo.`,
	"Manatees": "Manatees",
	"project_Manatees": `We located a pair of Manatees, of a governmental authority
		in South-America, which was willing to offer them on permanent loan
		to a zoo in Spain; they were transported by using a chartered plane.`,
	"Indian rhinos": "Indian rhinos",
	"project_Indian rhinos": `We located a zoo in the Netherlands that was willing to donate
		a male Indian rhinoceros to a zoo in South-East Asia to complete a pair.
		International Zoo Services provided the crate and arranged for its transport.`,
	"White lions": "White lions",
	"project_White lions": `White lions are rarely seen in zoos. We relocated a white lion from
		a private collection in Africa to a governmental zoo in the U.S.A.`,
	"Night Safari": "Night Safari",
	"project_Night Safari": `Another large transport took place in the 90's when a group of 65
		animals were transferred from several different zoos in Europe to a
		Night-safari in Southeast Asia.`,
	"Orangutan": "Orangutan",
	"project_Orangutan": `In 2017 a new home was found for an adult Orangutan in China.
		Transport from Spain was successful and with minimum stress for the animal.`,
	"Otters": "Otters",
	"project_Otters": `Nice groups of small-clawed otter from Europe were relocated
		to excellent destinations in China and U.S.A. during 2018.`,
	"White Tigers": "White Tigers",
	"project_White Tigers": `A couple of White tigers has found their new
		home in a big zoo in Thailand.`,
	"Sea Lions": "Sea Lions",
	"project_Sea Lions": `Groups of fur seals from South-America were
		relocated at zoos and aquariums in China, Indonesia, Bahrain, Egypt and
		many other countries.`,
	"User settings": "User settings",
	"Personal information": "Personal information",
	"About you": "About you",
	"Next": "Next",
	"Corporate information": "Corporate information",
	"About your institution": "About your institution",
	"Previous": "Previous",
	"Back to start": "Back to start",
	"Close": "Close",
	"Submit": "Submit",
	"Details of the interest": "Details of the interest",
	"Sections of interest (select the sections you are interested in)": "Sections of interest (select the sections you are interested in)",
	"Zoo animals (all categories, mammals, birds, reptiles, fishes, etc.)": "Zoo animals (all categories, mammals, birds, reptiles, fishes, etc.)",
	"Birds (all species of birds)": "Birds (all species of birds)",
	"Aquatic species (Sealions, otters, pengiuns, sharks, etc)": "Aquatic species (Sealions, otters, pengiuns, sharks, etc)",
	"Primates": "Primates",
	"Small mammals (marmosets, kangaroos, rodents, etc.)": "Small mammals (marmosets, kangaroos, rodents, etc.)",
	"Reptiles, Amphibians, Fishes, others": "Reptiles, Amphibians, Fishes, others",
	"Carnivores": "Carnivores",
	"register_would_like_to_receive_surplus": `I would like to receive surplus and wanted lists about my section of interest
		that indicated, as well as information about related products`,
	"register_do_not_want_to_receive_surplus": "I do not want to receive surplus and wanted lists about my section of interest that indicated.",
	"More information": "More information",
	"register_page___describe_your_institution": `Give a short description about your facility. `,
	"register_page_header_note": "Please note that only serious applications will be considered!",
	"register_page___describe_your_institution_textarea": `For example, describe your collection of animals and/or your activities in the zoological field; information about the zoos that you have contact with or about your membership of an association, is also very welcome.`,
	"register_modal_title_1": `Give a short description about your collection of animals and/or
		your activities in the zoologic field:`,
	"register_modal_title_2": `In case you are not open to the public, please inform us which
		public zoos you have contact with:`,
	"register_modal_title_3": `Are you a member of any animal related association? If so, please
		mention its name(s)`,
	"register_modal_title_4": `At what phone number we can call you for possible more information?`,
	"website_wrong_url": `The website or Facebook page is not a valid URL.`,
	"extra_info_warning": `Please, check your info, your description must be of at least 15 letters. Please review your application. Fields with errors have been marked in red`,
	"extra_info_required": `If you do not have a website, you must check the "no website" option and fill out the form that will appear when you click "Next".`,
	"interest_section_info_required": `You must mark at least one interest section.`,
	"newsletters_info_required": `You must mark if you want to receive surplus and wanted lists based on the sections of interest that you indicated.`,
	"Your answer": "Your answer",
	"Can anyone be a user": "Can anyone be a user",
	"of our databank of International Zoo Services (IZS)?": "of our databank of International Zoo Services (IZS)?",
	"register_q_1": `No, only institutions and professionals involved in the zoological
		field are allowed to be a user. That means: zoos, reliable animal-suppliers,
		zoo-animal-consultants, private breeding centres, rescue-centres,
		sanctuaries etc. Circus and research-institutes will not be allowed
		to use our data-bank. In case that you are looking for an exotic animal
		to keep as a pet, we are not the source; privately-owned breeding-centres,
		not open for the public, with the only purpose of breeding species, are welcome too.`,
	"After registration": "After registration",
	"will I automatically be a user of the databank?": "will I automatically be a user of the databank?",
	"register_q_2": `No, your profile will be approved by the administrator; in case your
		details are in conformity with our conditions you will receive a log-in code.
		If your profile does not match, IZS reserves the rights to reject your application.`,
	"Do other users get entrance": "Do other users get entrance",
	"to my details?": "to my details?",
	"register_q_3": `Other users will not see your personal name or the name of your institution.
		Your surplus and wanted records will be shared with others users as well as
		the details of the animals, only details about country or continent where you
		are located and type of institution you are. Other details are never shared in
		the website and are only used for our administration.`,
	"Why so many": "Why so many",
	"questions?": "questions?",
	"register_q_4": `We would like to know if your institution is suitable to participate
		in our website; other users also would like to know to whom they offer
		their animals or from which type of source the animals are originated.`,
	"Apply for an offer": "Apply for an offer",
	"Location-service: how we work": "Location-service: how we work",
	"howwework_text": `We could help you locate a particular animal that you wish to include in
		your collection or to find a new home for your surplus specimens. The new
		home for your surplus specimens will naturally be subject to your approval.`,
	"Transport and documentation-service: work for specialists": "Transport and documentation-service: work for specialists",
	"transport_text": `International Zoo Services can assist your organization
		with relevant documentation. Our team can coordinate animal transports
		by truck, boat or airplane; this includes taking care of crates or other
		suitable containers. On various occasions we have chartered a complete
		airplane for an animal transport.`,
	"Breeding-loan and donations: especially for eaza or aza-members": "Breeding-loan and donations: especially for eaza or aza-members",
	"Exchange": "Exchange",
	"exchange_text": `In case you want to exchange your surplus specimen for specimens that you
		wish to include in your collection, we can be of assistance to you. We will
		locate a zoo that is interested in your surplus-animals and provide you with
		the specimen that you are looking for on an exchange-base.`,
	"Special projects: donations from government to government": "Special projects: donations from government to government",
	"special_projects_text": `Whenever the relocation of an animal involves extremely rare species that
		are being donated by governmental institutions; International Zoo Services knows
		all the ins-and-outs of the laws and practices of that particular country and
		can secure the support of the governmental authorities involved.`,
	"Breeding-loan and donations: especially for EAZA or AZA -members": "Breeding-loan and donations: especially for EAZA or AZA -members",
	"breeding_text": `International Zoo Services also put them in direct contact with each other.
		This service is often used when a zoo desires an animal on a breeding-loan
		terms or wishes to make a donation.`,
	"Follow our work and projects on:": "Follow our work and projects on:",
	"Male": "Male",
	"Female": "Female",
	"Sex is unknown": "Sex is unknown",
	"Pair": "Pair",
	"several specimens available": "several specimens available",
	"captive bred": "captive bred",
	"wild caught": "wild caught",
	"Availability": "Availability",
	"The following specimen": `The following specimen(s) has been added to your inquiry-list for applying an offer:`,
	"closepanel": "Close this panel",
	"gotoinquirypage": "Review application and send inquiry",
	"Welcome to International Zoo Services": "Welcome to International Zoo Services",
	"Please provide your credentials to log in as an existing user": "Please provide your credentials to log in as an existing user",
	"You're logged in": "You're logged in",
	"Username": "Username",
	"Password": "Password",
	"Filter species": "Filter species",
	"We're sorry, there are no results": "We're sorry, there are no results",
	"Learn more": "Learn more",
	"Search species in English or scientific name": "Search species in English or scientific name",
	"Related animals": "Related animals",
	"dragdropfiles": "Drag 'n' drop some files here, or click to select files",
	"dropthefiles": 'Drop the files here ...',
	"Remarks": "Remarks",
	'Send files': 'Send files',
	"Files": "Files",
	"loginwarn_inquiry": `You have to be logged in to create an inquiry, please click the button below to
		enter our login page`,
	"Please select which department you want to contact:": "Please select which department you want to contact:",
	"Question body:": "Question body:",
	"Administrations department: about invoices and payments": "Administrations department: about invoices and payments",
	"Animal database manager: to send your surplus and wanted lists": "Animal database manager: to send your surplus and wanted lists",
	"Flamingo-services: about projects from Cuba, including flights schedules": "Flamingo-services: about projects from Cuba, including flights schedules",
	"Inquiry-offer manager: about inquiries for prices, permits of projects": "Inquiry-offer manager: about inquiries for prices, permits of projects",
	"Transport-manager: about flight schedules, truck-transports": "Transport-manager: about flight schedules, truck-transports",
	"Others: any other subject": "Others: any other subject",
	"Submit your inquiry": "Submit your inquiry",
	"loginwarn_contact": "You have to be logged in to be able to fill out the contactform, please follow the link below to log in",
	"contactus_name": "Contact name:",
	"contactus_institution": "Contact institution:",
	"contactus_email": "Contact email:",
	"contactus_country": "Contact country:",
	"First Name": "First Name",
	"Last Name": "Last Name",
	"Country": "Country",
	"City": "City",
	"Email address": "Email address",
	"Create password": "Create password",
	"Repeat Password": "Repeat Password",
	"Private breeding farm": "Private breeding farm",
	"Animal supplier": "Animal supplier",
	"Zoo and public facilities": "Zoo and public facilities",
	"Animal consult and vet": "Animal consult and vet",
	"In case you are a private person": `
		In case you are a private person and not acting as an institution,
		please mention your name
	`,
	"Phone": "Phone",
	"Your position": "Your position",
	"Website": "Website",
	"Facebook page": "Facebook page",
	"No Website": "No Website",
	"Information about available and wanted species": "Information about available and wanted species",
	"Name of insitution": "Name of insitution",
	"Type of insitution": "Type of insitution",
	'no_animals_added': 'No animals added yet, go add some!',
	'Welcome': 'Welcome',
	'Check out our animals': `check out our animals. We have pages for mammals, birds, reptiles,
		amphibians, fish and more! You can check our animals and create an inquiry for one or
		multiple specimen`,
	'Select all': 'Select all',
	'Remove': 'Remove',
	'Registered': 'Registered!',
	'Check email': 'Check your email for more info',
	'approval': 'Your application is now in the process of approval.',
	'Error sending': 'Error while sending your application.',
	'Support': 'Contact us for support :',
	'Whatsapp': 'Phone/whatsapp: +31854011610',
	'Types': 'Types',
	'Available': 'Available',
	'Requested': 'Requested',
	'Animal is removed from your inquiry': 'Animal is removed from your inquiry',
	'Update your registration details': 'Update your registration details',
	'Sort on last modified': 'Sort on last modified',
	'Sort by name': 'Sort by name',
	'Message received': 'Message received',
	'Something went wrong': 'Something went wrong',
	'Leave blank to keep password unchanged': 'Leave blank to keep password unchanged',
	'mand_info_missing': `There is mandatory information missing, please review your
		application. Missing fields have been marked in red.`,
	'mand_info_missing2': `There is mandatory information missing, please review your application.`,
	'passwd_mismatch': `Entered passwords are not identical, please check your entry`,
	'Last modified': 'Last modified',
	'Taxonomy': 'Taxonomy',
	'Common name (A-Z)': 'Common name (A-Z)',
	'Common name (Z-A)': 'Common name (Z-A)',
	'Scientific name (A-Z)': 'Scientific name (A-Z)',
	'Scientific name (Z-A)': 'Scientific name (Z-A)',
	'Settings updated successfully': 'Settings updated successfully',
	'Download inventorylist': 'Download inventorylist',
	'Thank you for your interest': 'Thank you for your interest',
	'We appreciate it': 'We appreciate it',
	'inventorylist-loggedin': `
		International Zoo Services is a consultancy group for zoological collections worldwide,
		specialized in the relocation of animals. On this website, you can find all the
		animals we currently have to offer. However, if you prefer to download a PDF version,
		please use the button below to download that.
	`,
	'inventorylist-notloggedin': `
		International Zoo Services is a consultancy group for zoological collections worldwide,
		specialized in the relocation of animals.On this website, you can find all the
		animals we currently have to offer. However, if you prefer to download a PDF version,
		you need to be registered. Please use the button below to go to our registration page.
	`,
	'Download PDF inventorylist': 'Download PDF inventorylist',
	'Logout': 'Log out',
	'Forgot Password' : 'Forgot Password',
	'Get new password' : 'Get new password',
	'Sorry, we could not find your e-mail address' : 'Sorry, we could not find your e-mail address',
	'Password reset' : 'Reset password',
	'Enter your password below and confirm' : 'Enter a new password, confirm it and click \'Update\'',
	'Update': 'Update',
	'Repeat password': 'Repeat password',
	'Please enter a password and repeat it': 'Please enter a password and repeat it',
	'Passwords don\'t match': 'Passwords don\'t match',
	'Password successfully reset': 'Password successfully reset',
	'Sorry, something went wrong': 'Sorry, something went wrong',
	'A password reset link has been sent to your e-mail address' : 'A password reset link has been sent to your e-mail address',
	'Submited': 'Submited!',
	'Your inquiry sent': 'Your Inquiry was sent!',
	'More info': 'Check your email for future info',
	'Problem inquiry': 'There was an error whith your Inquiry',
	'Contact us for support': 'Contact us for more info: ',
	'Stuffed we support': 'We supply various taxidermy objects for expositions in zoos, natural history museums and private collections.</br> Please <a href="/register">Apply here</a> for our inventory list.',
	'Client abbr':`<span class="abbrs">Abbreviations</span>  </br>      
	<span class="abbrs">M</span> : Males <span class="abbrs">F</span>: Females <span class="abbrs">U</span>: Specimen of which sex is unknown.
	<span class="abbrs">c.b</span> : Captive bred <span class="abbrs">w.c</span> : Wild caught </br> 
	<span class="abbrs">c.b/w.c</span> : Mixed group of captive bred and wild caught specimen.
	Ex Institution: price for just the specimen, without costs of crate and any transport.</br>
	<span class="abbrs">FOB</span> : Price of the specimen including costs of crate and transport to the airport of departure.</br>
	<span class="abbrs">C+F</span> : Price of the specimen including costs of crate and transport to the airport of destination.`,
	'Supplier abbr':`<span class="abbrs">Abbreviations</span> </br>
		<span class="abbrs">M</span> : Males <span class="abbrs">F</span>: Females <span class="abbrs">U</span>: Specimen of which sex is unknown.
	<span class="abbrs">c.b</span> : Captive bred <span class="abbrs">w.c</span> : Wild caught </br> 
	<span class="abbrs">c.b/w.c</span> : Mixed group of captive bred and wild caught specimen.
	Ex Institution: price for just the specimen, without costs of crate and any transport.</br>
	<span class="abbrs">FOB</span> : Price of the specimen including costs of crate and transport to the airport of departure.`,
	'client conditions': 'Conditions for Client',
	'supplier conditions': 'Conditions for Supplier',
	'All about zoo-management':'All about zoo-management',
	'Zoo management':'Zoo management',
	'Feeding': 'FEEDING',
	'Husbandry': 'HUSBANDRY EQUIPMENT',
	'Transport': 'TRANSPORT',
	'Educational material': 'EDUCATIONAL MATERIAL',
	'Zoo designers': 'ZOO DESIGNERS AND ARCHITECTS',
	'partner title':'OUR PARTNERS :',
	'our partners' : 'Our worldwide partner network can assist you in the following areas of Zoo Management.',
	'client rules':'Our delivery conditions',
	'supplier rules':'Suppliers conditions'
}

export const es = {
	"Home": "Inicio",
	"FAQ": "FAQ",
	"Faq": "Faq",
	"Upload": "Subir",
	"Contact us": "Contacto",
	"Inquiries to apply": "Pedidos a solicitar",
	"Login": "Entrar",
	"Sorry": "Lo sentimos",
	"Please": "Por favor",
	"Note that":"Tenga en cuenta que periódicamente enviamos listas de especies que están disponibles, así como de las que son necesarias. Nuestros servicios son de zoológico en zoológico. También contamos con mailings sobre temas especializados. Si desea recibir información periódicamente, regístrese.",
	"You need to be registered to access the complete surplus list": "Es necesario estar registrado para acceder a la lista completa de excedentes",
    "If you are not registered, register":"Si no estás registrado, regístrate",
	"here": "aquí",
	"If you are registered please": "Si estás registrado por favor ",
	"log in": "inicia tu sesión",
	"Register": "Registrarse",
	"Surplus/Wanted lists": "Listas Excedentes/Buscados",
	"Since 1985": `Desde 1985 el servicio de<br />consultoría para zoológicos<br />en todo el mundo.`,
	"About us": "Nosotros",
	"Del Rules": "Nuestras condiciones de entrega",	
	"Sup Rules":"Condiciones de proveedores",
	"Species to relocate and special projects": "Especies para reubicar y proyectos especiales",
	"See all": "Ver todo",
	"Location-service: how we work": "Servicios de localización",
	"Transport and documentation-service: work for specialists": "Servicios de transporte y documentacion: !tarea para especialistas!",
	"transport_text": `International Zoo Services puede ayudarle con
		la documentación pertinente. Nuestro equipo puede coordinar el
		transporte de animales por camión, barco o avión; esto incluye
		el cuidado de las jaulas u otros contenedores adecuados. En
		varias ocasiones hemos alquilado un avión completo para un
		transporte de animales.`,
	"Breeding-loan and donations: especially for eaza or aza-members": "Crianza-préstamo y donaciones",
	"Exchange": "Intercambios",
	"exchange_text": `En caso de que desee intercambiar sus especies
		excedentes por otras que desee incluir en su colección, podemos ayudarlo.
		Localizaremos un zoológico que esté interesado en sus animales excedentes
		y le proporcionaremos el espécimen que está buscando como forma de intercambio.`,
	"Special projects: donations from government to government": "Proyectos especiales",
	"special_projects_text": `Cuando la reubicación de un animal involucra especies
		extremadamente raras que son donadas por instituciones gubernamentales;
		International Zoo Services conoce todos los entresijos de las leyes y
		prácticas de ese país en particular y pueda asegurar el apoyo de las
		autoridades gubernamentales involucradas.`,
	"A worldwide network": "Red global",
	"Contacts with over 15000 institutions": "Contactos con más de 15000 instituciones",
	"izs_intro": `International Zoo Services es un grupo de consultoría
		para colecciones zoológicas de todo el mundo, especializado en
		la reubicación de animales. Fue fundado en 1985 por John Rens,
		ex subdirector del zoológico de Wassenaar, Países Bajos. Este
		zoológico fue muy reconocido por sus resultados de reproducción
		con bonobos, gorilas, guepardos y aves de presa.`,
	"readmore": 'Leer mas',
	"Transport directly from zoo to zoo": "Transporte directamente de zoológico a zoológico",
	"Live animal transport coordinations": "Coordinaciones de transporte de animales vivos",
	"izs_intro_2": `International Zoo Services is a consultancy group
		for zoological collections worldwide, specialized
		in the relocation of animals. It was founded in 1985
		y John Rens, former deputy-director of Wassenaar Zoo,
		The Netherlands. This zoo was famous for their breeding
		results with bonobos, gorillas, cheetahs, and birds of prey.`,
	"RELOCATION OF A LARGE VARIETY OF SPECIES": "REUBICACIÓN DE UNA GRAN VARIEDAD DE ESPECIES",
	"Our worldwide network": `Nuestra red global puede ayudarle a
		localizar las especies deseadas y encontrar nuevos hogares
		para sus especies excedentes.`,
	"Our clients are": "Nuestros clientes son",
	"ourclientsare_text": `En su mayoría parques zoológicos y acuarios de todo el mundo,
		abiertos al público con fines educativos, así como centros de cría reconocidos que gestionan
		su colección de animales de acuerdo con los estándares aprobados vigentes.`,
	"Zoos & private breeding centers": "Zoológicos y criaderos privados",
	"about_us_intro": `<span>International Zoo Services es un grupo de
		consultoría para zoológicos de todo el mundo, especializado en
		la reubicación de animales. Fue fundada en 1985 por John Rens,
		ex subdirector del zoológico de Wassenaar, Holanda. Este zoológico
		era famoso por sus resultados reproductivos con bonobos, gorilas,
		guepardos y aves de presa.<br /><br />
		Nuestra organización está coordinando los intercambios y el
		transporte de animales a los zoológicos de todo el mundo; nuestro equipo
		mantiene un banco de datos de miles de zoológicos. Esta red mundial
		puede ayudarlo a recopilar la información adecuada para realizar
		sus programas de cría.</span>`,
	"Founder of International Zoo Services": "fundador de International Zoo Services",
	"howwework_text": `Podríamos ayudarlo a encontrar un animal en
		particular que desee incluir en su colección o un nuevo hogar
		para sus especímenes excedentes. La nueva institución para
		sus especímenes excedentes estará sujeta a su aprobación.`,
	"Breeding-loan and donations: especially for EAZA or AZA -members": "Crianza-préstamo y donaciones",
	"breeding_text": `International Zoo Services también facilita el contacto
		directo entre diferentes zoológicos. Este servicio se usa a menudo
		cuando un zoológico desea un animal bajo los términos de un préstamo
		de reproducción o desea hacer una donación.`,
	"Follow our work and projects on:": "Siga nuestro trabajo y proyectos en:",
	"Our projects": "Algunos de nuestros proyectos",
	"ourprojects_subtitle": `Para ilustrar el trabajo de International Zoo
		Services les mostramos algunos de nuestros proyectos favoritos,
		completados a lo largo de los últimos años:`,
	"ourprojects_subtitle_home": `Para ilustrar el trabajo de International Zoo Services`,
	"Penguins": "Pingüinos",
	"project_Penguins": `Un gran grupo de pingüinos, incluidos los
		pingüinos rey, los pingüinos de penacho amarillo, los pingüinos
		de Magallanes y pingüinos de Vincha, se encontraban en América
		del Sur y fueron transportados a un nuevo zoológico en España.`,
	"Giraffes": "Jirafas",
	"project_Giraffes": `Una pareja de jirafas fue transportada desde
		Europa hasta Brasil, ni que decir, un proyecto muy altamente complicado.`,
	"King-cheetahs": "Rey-guepardo",
	"project_King-cheetahs" : `Un par de raros guepardos dejaron África
		bajo nuestro cuidado rumbo a su nuevo hogar en un centro de
		cría especializado en el Medio Oriente.`,
	"Giant tortoises": "Tortugas gigantes",
	"project_Giant tortoises": `Las tortugas gigantes gozan de gran popularidad
		con el público. Un gran grupo de estos enormes reptiles de un
		centro de cría especializado fueron transportados a varios zoológicos
		en Europa y Asia.`,
	"Asian Elephants": "Elefantes asiáticos",
	"project_Asian Elephants": `Varios elefantes asiáticos fueron importados
		a Alemania, acompañados por algunos mahouts. Los elefantes precedían
		de diferentes países del sudeste asiático.`,
	"Gang-gang cockatoos": "Cacatúas Gang-gang",
	"project_Gang-gang cockatoos": `Se ubicó un par de cacatúas Gang-gang, una especie
		muy cotizada y no disponible con frecuencia.`,
	"Bongo": "Bongo",
	"project_Bongo": `Uno de nuestros primeros proyectos tuvo lugar durante
		los años ochenta, cuando Europa oriental y occidental todavía estaban
		separadas. Gestionamos la transferencia de un Bongo macho de
		Checoslovaquia a un zoológico en Alemania Occidental, donde participó
		en un programa de cría muy exitoso.`,
	"Clouded leopards": "Leopardos nebulosos",
	"project_Clouded leopards": `Una pareja de panteras nebulosas fue enviada desde un
		centro de cría privado en los EE.UU a un zoológico en Tokio.`,
	"Manatees": "Manatíes",
	"project_Manatees": `Localizamos una pareja de manatíes, de una autoridad
		gubernamental en Sudamérica, ofrecidos en préstamo permanente a un
		zoológico en España; Fueron transportados utilizando un avión fletado.`,
	"Indian rhinos": "Rinocerontes indios",
	"project_Indian rhinos": `Localizamos un zoológico en los Países Bajos dispuesto
		a donar un rinoceronte indio macho a un zoológico en el sudeste asiático
		para completar la pareja. International Zoo Services proporcionó el
		huacal y organizó el transporte.`,
	"White lions": "Leones blancos",
	"project_White lions": `Los leones blancos rara vez se vistos en los parques zoológicos.
		Reubicamos un león blanco de una colección privada en África a un
		zoológico gubernamental en los EE.UU.`,
	"Night Safari": "Safari nocturno",
	"project_Night Safari": `Otro gran transporte tuvo lugar en los años 90,
		cuando un grupo de 65 animales se transfirieron desde diferentes
		zoológicos en Europa a un Safari nocturno en el sudeste asiático.`,
	"Orangutan": "Orangután",
	"project_Orangutan": `En el año 2017, se encontró un nuevo hogar para
		un orangután adulto en China. El transporte desde España fue exitoso
		y con mínimo estrés para el animal.`,
	"Otters": "Nutrias",
	"project_Otters": `Varios atractivos grupos de nutrias provenientes
		de Europa se reubicaron en excelentes destinos en China y Estados
		Unidos durante el 2018.`,
	"White Tigers": "Tigres blancos",
	"project_White Tigers": `Una pareja de tigres blancos ha encontrado
		su nuevo hogar en un gran zoológico de Tailandia.`,
	"Sea Lions": "Leones marinos",
	"project_Sea Lions": `Grupos de lobos marinos de América del Sur
		fueron reubicados a zoológicos y acuarios en China, Indonesia,
		Barein, Egypto y muchos otros países.`,
	"Male": "Macho",
	"Apply for an offer": "Solicita una oferta",
	"Female": "Hembra",
	"Sex is unknown": "Sexo desconocido",
	"Pair": "Pareja",
	"several specimens available": "varios ejemplares disponibles",
	"captive bred": "cautiverio",
	"wild caught": "capturado salvaje",
	"Availability": "Disponibilidad",
	"The following specimen": `Las siguientes especies han sido agregadas a su lista de solicitudes para aplicar a una oferta:`,
	"Welcome to International Zoo Services": "Bienvenido a International Zoo Services",
	"Please provide your credentials to log in as an existing user": "Proporcione sus credenciales para iniciar sesión como usuario existente",
	"You're logged in": "Estás conectado",
	"Username": "Nombre de usuario",
	"Password": "Contraseña",
	"Close": "Cerrar",
	"Submit": "Enviar",
	"Filter species": "Filtrar especies",
	"We're sorry, there are no results": "Lo sentimos, no hay resultados.",
	"Your inquiry": "Su consulta",
	"Learn more": "Aprende más",
	"Search species in English or scientific name": "Buscar especies en inglés o nombre científico",
	"Related animals": "Animales relacionados",
	"dragdropfiles": "Arrastre y suelte algunos archivos aquí, o haga clic para seleccionar archivos",
	"dropthefiles": 'Suelta los archivos aquí ...',
	"Remarks": "Observaciones",
	"Send files": "Enviar archivos",
	"Files": "Archivos",
	"loginwarn_inquiry": `Debe iniciar sesión para crear una consulta, haga clic en el botón de abajo para
		ingrese a nuestra página de inicio de sesión`,
	"Submit your inquiry": "Envíe su consulta",
	"Please select which department you want to contact:": "Por favor, seleccione el departamento que desea contactar:",
	"Question body:": "Pregunta:",
	"Administrations department: about invoices and payments": "Departamento administradores: sobre facturas y pagos",
	"Animal database manager: to send your surplus and wanted lists": "Gestor de base de datos de animales: para enviar su lista de animales diponibles y buscados",
	"Flamingo-services: about projects from Cuba, including flights schedules": "Servicios-flamencos: sobre proyectos de Cuba, incluyendo tiempo de entrega",
	"Inquiry-offer manager: about inquiries for prices, permits of projects": "Gestor de ofertas: sobre precios de solicitudes, permisos de los proyectos.",
	"Transport-manager: about flight schedules, truck-transports": "Gestor de transporte: sobre programación de vuelos, transportes por camión.",
	"Others: any other subject": "Otros: cualquier otra solicitud",
	"loginwarn_contact": "Debe iniciar sesión para poder completar el formulario de contacto, siga el enlace a continuación para iniciar sesión",
	"contactus_name": "Nombre de contacto:",
	"contactus_institution": "Nombre de institución:",
	"contactus_email": "Correo de contacto:",
	"contactus_country": "País de contacto:",
	"Next": "Próximo",
	"About you": "Acerca de usted",
	"Corporate information": "Información corporativa",
	"About your institution": "Acerca de su institución",
	"Previous": "Previo",
	"Back to start": "Volver a empezar",
	"Personal information": "Informacion personal",
	"Details of the interest": "Detalles del interés",
	"First Name": "Nombre(s)",
	"Last Name": "Apellido(s)",
	"Country": "País",
	"City": "Ciudad",
	"Email address": "Dirección de correo electrónico",
	"Create password": "Contraseña",
	"Repeat Password": "Repita la contraseña",
	"Private breeding farm": "Granja de cría privada",
	"Animal supplier": "Proveedor de animales",
	"Zoo and public facilities": "Zoo e instalaciones públicas",
	"Animal consult and vet": "Consultor y veterinario",
	"In case you are a private person": `
		En caso de ser un privado y no actue como una institucion,
		por favor mencione tu nombre.
	`,
	"Phone": "Teléfono",
	"Your position": "Su función",
	"Website": "Sitio web",
	"Facebook page": "Sitio en Facebook",
	"No Website": "No sitio web",
	"Information about available and wanted species": "Información acerca de especies disponibles y buscadas",
	"Sections of interest (select the sections you are interested in)": "Seleccione las secciones que sean de su interés",
	"Name of insitution": "Nombre de institución",
	"Type of insitution": "Tipo de institución",
	"register_modal_title_1": `Dé una breve descripción sobre su colección de animales y / o
		sus actividades en el campo zoológico:`,
	"register_modal_title_2": `En caso de que no esté abierto al público, infórmenos qué
	zoológicos públicos con los que tiene contacto:`,
	"register_modal_title_3": `¿Eres miembro de alguna asociación relacionada con los animales? Si es así por favor
	mencionar su (s) nombre (s)`,
	"register_modal_title_4": `¿A qué número de teléfono podemos llamarle para obtener más información?`,
	"website_wrong_url": `El sitio web o la página de Facebook no es una URL válida.`,
	"extra_info_warning": `Por favor, revise sus datos, tu descripción debe ser de al menos 15 letras. Debera revisar los campos marcados en rojo`,
	"extra_info_required": `En caso de no tener sitio web, debe marcar la opción "no website" y llenar el formulario que aparecerá al hacer click en "Siguiente".`,
	"interest_section_info_required": `Debe marcar al menos 1 sección de interés.`,
	"newsletters_info_required": `Debe seleccionar si desea recibir listas de excedentes y buscados sobre las secciones de interés que indicó.`,
	"More information": "Más información",
	"Your answer": "Tu respuesta",
	"Zoo animals (all categories, mammals, birds, reptiles, fishes, etc.)": "Todas las especies (mamíferos, aves, reptiles, peces, etc)",
	"Birds (all species of birds)": "Aves (todas las espcies de aves)",
	"Aquatic species (Sealions, otters, pengiuns, sharks, etc)": "Especies acuáticas (Leones marinos, nutrias, pingüinos, tiburones, etc)",
	"Primates": "Primates",
	"Small mammals (marmosets, kangaroos, rodents, etc.)": "Mamíferos pequeños (marmosets, canguros, rodentes, etc)",
	"Reptiles, Amphibians, Fishes, others": "Reptiles, Anfibios, Peces, otros",
	"Carnivores": "Carnívoros",
	"register_would_like_to_receive_surplus": `Deseo recibir listas de especies disponibles
		y buscadas acerca de las secciones de interés especificadas, así como información asociada al producto `,
	"register_do_not_want_to_receive_surplus": "No deseo recibir listas de especies disponibles ni buscadas sobre la sección de interés especificada",
	"register_page___describe_your_institution": `Proporcione una breve descripción de sus instalaciones.`,
	"register_page_header_note": "¡Tenga en cuenta que solo se considerarán solicitudes serias!",
	"register_page___describe_your_institution_textarea": `Por ejemplo, describa su colección de animales y/o sus actividades en el ámbito zoológico; información sobre los zoológicos con los que tiene contacto o sobre su membresía en alguna asociación también será muy bienvenida.`,
	"Can anyone be a user": "¿Todos pueden ser usuarios",
	"of our databank of International Zoo Services (IZS)?": "de nuestro banco de datos de International Zoo Services (IZS)?",
	"register_q_1": `No, sólo instituciones y profesionales involucrados en
		el campo zoológico pueden ser usuarios. Lo cual significa:
		zoológicos, proveedores confiables de animales, consultores
		de zoológicos, centros privados de cría, centros de rescate,
		santuarios, etc. No se permitirá que circos ni institutos de
		investigación usen nuestro banco de datos. Además, si está
		buscando un animal exótico para tener como mascota, no somos
		la fuente; los centros de cría de propiedad privada, que no
		están abiertos al público, con el único propósito de reproducir
		especies, también son bienvenidos.`,
	"After registration": "Después del registro",
	"will I automatically be a user of the databank?": "¿seré automáticamente usuario del banco de datos?",
	"register_q_2": `No, su perfil será aprobado por el administrador. En caso de
		que sus datos cumplan con nuestras condiciones, recibirá un código
		de inicio de sesión. Si su perfil no coincide, IZS se reserva el
		derecho de rechazar su solicitud.`,
	"Do other users get entrance": "¿Los otros usuarios",
	"to my details?": "obtienen acceso a mis datos?",
	"register_q_3": `Los Otros usuarios no verán su nombre personal o el
		nombre de su institución. Sus registros de animales excedentes y
		buscados pueden ser compartidos con otros usuarios, además de
		detalles específicos de los animales, solo detalles sobre el
		país o continente donde se encuentra y qué tipo de institución es
		son compartidos. Otros detalles nunca se muestran en el sitio web
		y sólo se utilizan para nuestra administración.`,
	"Why so many": "¿Por qué tantas",
	"questions?": "preguntas?",
	"register_q_4": `Nos gustaría saber si su institución es adecuada para
		participar en nuestro sitio web; otros usuarios también querrán saber
		a quiénes ofrecen sus animales o de qué tipo de fuente se originan.`,
	'no_animals_added': 'Aún no se han agregado animales, ve y agrega algunos!',
	'Welcome': 'Bienvenido',
	'Check out our animals': `echa un vistazo a nuestros animales. ¡Tenemos
		páginas para mamíferos, aves, reptiles, anfibios, peces y más! Puede
		consultar nuestros animales y crear una consulta para uno o varios ejemplares.`,
	'Select all': 'Seleccionar todo',
	'Remove': 'Eliminar',
	'Registered': 'Registrado!',
	'Check email': 'Revise su email para mas información.',
	'approval': 'Su aplicación se encuentra en proceso de aprobación.',
	'Error sending': 'Error mientras se enviaba su aplicación.',
	'Support': 'Contáctenos para ayudarlo :',
	'Whatsapp': 'Teléfono/whatsapp: +31854011610',
	'Types': 'Tipos',
	'Available': 'Disponible',
	'Requested': 'Solicitado',
	'Animal is removed from your inquiry': 'Animal es eliminado de su consulta',
	"closepanel": "Cerrar este panel",
	"gotoinquirypage": "Revise la solicitud y envíe su consulta",
	'Update your registration details': 'Actualice sus datos de registro',
	'Sort on last modified': 'Sort on last modified',
	'Sort by name': 'Ordenar por nombre',
	'Message received': 'Mensaje recibido',
	'Something went wrong': 'Algo salió mal',
	'Leave blank to keep password unchanged': 'Déjelo en blanco para mantener la contraseña sin cambios',
	'mand_info_missing': `Falta información obligatoria, revise su
		solicitud. Los campos que faltan se han marcado en rojo.`,
	'mand_info_missing2': `Falta información obligatoria, revise su solicitud.`,
	'passwd_mismatch': `Las contraseñas ingresadas no son idénticas, verifique su entrada`,
	'Last modified': 'Última modificación',
	'Taxonomy': 'Taxonomía',
	'Common name (A-Z)': 'Nombre común (A-Z)',
	'Common name (Z-A)': 'Nombre común (Z-A)',
	'Scientific name (A-Z)': 'Nombre científico (A-Z)',
	'Scientific name (Z-A)': 'Nombre científico (Z-A)',
	'Settings updated successfully': 'La configuración se actualizó correctamente',
	'Download inventorylist': 'Descargar lista de inventario',
	'Thank you for your interest': 'Gracias por su interés',
	'We appreciate it': 'Lo apreciamos',
	'inventorylist-loggedin': `
		International Zoo Services es un grupo de consultoría para colecciones zoológicas en todo el mundo,
		especializada en la reubicación de animales. En este sitio web, puede encontrar todos los
		animales que actualmente tenemos para ofrecer. Sin embargo, si prefiere descargar una versión PDF,
		utilice el botón de abajo para descargarlo.
	`,
	'inventorylist-notloggedin': `
		International Zoo Services es un grupo de consultoría para colecciones zoológicas en todo el mundo,
		especializada en la reubicación de animales, en esta web podrás encontrar todos los
		animales que actualmente tenemos para ofrecer. Sin embargo, si prefiere descargar una versión PDF,
		necesita estar registrado. Utilice el botón de abajo para ir a nuestra página de registro.
	`,
	'Download PDF inventorylist': 'Descargar lista de inventario en PDF',
	'Logout': 'Cerrar sesión',
	'Forgot Password' : 'Has olvidado tu contraseña',
	'Get new password' : 'Obtener nueva contraseña',
	'A password reset link has been sent to your e-mail address' : 'Se ha enviado un enlace para restablecer la contraseña a su dirección de correo electrónico.',
	'Sorry, we could not find your e-mail address' : 'Lo sentimos, no pudimos encontrar su dirección de correo electrónico.',
	'Password reset' : 'Restablecer la contraseña',
	'Enter your password below and confirm' : 'Ingrese una nueva contraseña, confírmela y haga clic en \'Actualizar \'',
	'Update': 'Actualizar',
	'Repeat password': 'Repita la contraseña',
	'Please enter a password and repeat it': 'Ingrese una contraseña y repítala',
	'Passwords don\'t match': 'Las contraseñas no coinciden',
	'Password successfully reset': 'Contraseña restablecida con éxito',
	'Sorry, something went wrong': 'Perdón, algo salió mal',
	'Submited': 'Enviado!',
	'Your inquiry sended': 'Su petición fué enviada!',
	'More info': 'Revise su email para futura información',
	'Problem inquiry': 'Hubo un problema con su pedido',
	'Contact us for support': 'Contáctenos en: ',
	"Stuffed Animals": "Animales rellenos",
	'Stuffed we support': 'Suministramos diversos objetos de taxidermia para exposiciones en zoológicos, museos de historia natural y colecciones privadas.Por favor <a href="/register">Aplicar aquí</a> para nuestra lista de inventario.',
	'Client abbr':`<span class="abbrs">Abreviaturas</span>  </br>      
	<span class="abbrs">M</span> : Machos <span class="abbrs">F</span>: Hembras <span class="abbrs">U</span>: Ejemplar del que se desconoce el sexo.
	<span class="abbrs">c.b</span> : criado en cautiverio <span class="abbrs">w.c</span> : salvaje capturado </br> 
	<span class="abbrs">c.b./w.c</span> : Grupo mixto de ejemplares criados en cautividad y capturados en la naturaleza.
	Ex Institución: precio solo por el ejemplar, sin gastos de caja ni transporte.</br>
	<span class="abbrs">FOB</span> : Precio del ejemplar, incluidos los costes de embalaje y transporte al aeropuerto de salida.</br>
	<span class="abbrs">C+F</span> : Precio del ejemplar, incluidos los costes de embalaje y transporte al aeropuerto de destino.`,
	'Supplier abbr':`<span class="abbrs">Abreviaturas</span> </br>
		<span class="abbrs">M</span> : Machos <span class="abbrs">F</span>: Hembras <span class="abbrs">U</span>: Ejemplar del que se desconoce el sexo.
	<span class="abbrs">c.b</span> : criado en cautiverio <span class="abbrs">w.c</span> : salvaje capturado </br> 
	<span class="abbrs">c.b./w.c</span> : Grupo mixto de ejemplares criados en cautividad y capturados en la naturaleza.
	Ex Institución: precio solo por el ejemplar, sin gastos de caja ni transporte.</br>
	<span class="abbrs">FOB</span> : Precio del ejemplar, incluidos los costes de embalaje y transporte al aeropuerto de salida.`,
	'client conditions': 'Condiciones para la clienta',
	'supplier conditions': 'Condiciones para Proveedor',
	'All about zoo-management':'Todo sobre la gestión del zoológico',
	'Feeding': 'ALIMENTACIÓN',
	'Husbandry': 'EQUIPO DE Cría',
	'Transport': 'TRANSPORTE',
	'Educational material': 'MATERIAL EDUCATIVO',
	'Zoo designers': 'DISEÑADORES Y ARQUITECTOS DE ZOO',
	'partner title':'NUESTROS SOCIOS :',
	'our partners' : 'Nuestra red mundial de socios puede ayudarle en las siguientes áreas de gestión de zoológicos.',
	'Zoo management':'Gestión del zoológico',
	'client rules':'Nuestras condiciones de entrega',
	'supplier rules':'Condiciones de proveedores'
}