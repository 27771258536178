export const primates_links = [
    { "title" :"Food Links",
    "spanishTitle" :"Food Links",
    "links" : [
        {"ipath":"/images/mazure.jpg",
        "web_url":"http://www.mazuri.com"
        },
        {"ipath":"/images/versele.jpg",
            "web_url":"https://www.versele-laga.com"
        },
        {"ipath":"/images/fauna.jpg",
                "web_url":"https://www.faunafoods.com/"
        }
     
      
    ] 
},
{ "title" :"Fence and other materials",
        "spanishTitle" :"Fence and other materials",
        "links" : [
            {"ipath":"/images/heras.jpg",
            "web_url":"https://www.heras-mobile.com"
            }
          
        ] 
 },
 { "title" :"Maintanance Links",
    "spanishTitle" :"Maintanance Links",
    "links" : [
        {"ipath":"/images/mazure.jpg",
            "web_url":"http://www.mazuri.com"
            }
      
    ] 
},
{ "title" :"Educational labels",
    "spanishTitle" :"Educational labels",
    "links" : [
        {"ipath":"/images/mazure.jpg",
            "web_url":"http://www.mazuri.com"
            }
      
    ] 
},
{ "title" :"Transport and crates",
    "spanishTitle" :"Transport and crates",
    "links" : [
        {"ipath":"/images/mazure.jpg",
            "web_url":"http://www.mazuri.com"
            }
      
    ] 
},
{ "title": "Books and literature",
    "spanishTitle" :"Books and literature",
    "links" : [
        {"ipath":"/images/mazure.jpg",
            "web_url":"http://www.mazuri.com"
            }
      
    ] 
}
]


