export const clientConditions = [
    {
        "title": "General Conditions",
        "conditions":[
            "Delivery time needs to be agreed",
            "Partial shipments are possible",
            "IZS agreed with the supplying institution to keep the concerning animals reserved for the client during the permit procedure. IZS cannot accept any responsibility in case supplier, due to certain circumstances cannot supply concerning specimens in time, in that case a new delivery time will be agreed.",
            "Deposit payment is only refundable in case the supplier/IZS cannot fulfill the delivery as agreed.",
            "Any imperfection needs to be mentioned within 24 hours after arrival at airport of destination.",
            "In case of mortality, certificate made by a Veterinary Official within 24 hours after arrival is required. Dead bodies need to be stored in freezer till restitution of payment has been realized.",
            "Before accepting this reservation the receiver has investigated that all veterinary conditions for the import of concerning species can be realized.",
            "Due to Covid-19, pandemics, political situations, etc, changes in logistics processes can be expected. Delays and cancellation of flights/vessels/trucks might occur. Rates can fluctuate on an hourly basis. If the flight cannot proceed for any reason, the costs for the quarantine/stabling, the testing and other costs are for the applicant. IZS is not responsible for any costs due to previously mentioned reasons."
        ]
    },
    {
        "title": "Terms of payment",
        "conditions":[
            "Species that needed no special documents and can be shipped immediately: full payment at the moment of reservation.Species that needs a permit-application: 30% at the moment of reservation; the balance before shipment.Enclosed you may find our invoice. Your reservation will be considered definitive after receipt of your payment."
        ]
    },
    {
        "title": "Application of permits",
        "conditions":[
            "In case Export-CITES permit is required, the CITES-Permit application will be started as soon as the deposit-payment is received.",
            "If all permits are issued on both sides, but if the client needs to delay the shipment, all possible extra costs involved will be charged to the client."
        ]
    },
    {
        "title": "Before shipment",
        "conditions":[
            "Tests required for issuing veterinary Health Certificate are at extra cost, unless otherwise agreed. All export documents related to this transaction will be submitted by the supplying institution for your approval, 4 days prior shipment.",
            "Please inform us by return if they are in conformity with the corresponding requirements from authorities of the country of final destination of the species."
        ]
    },
			
]
export const supplierConditions = [
    {
        "title": "General Conditions",
        "conditions":[
            "Delivery time need to be agreed.",
            "If supplier cannot ship the animals before delivery time ends, IZS has the right to cancel the order or a delay of shipment can be considered, with the condition that a percentage of 2% of the cost price for every week of delay will be deducted from the final invoice, with a maximum of 2 month total delay.",
            "Supplier will inform IZS about any cosmetically defects, sterilization, non-compatibility before the reservation is confirmed.",
            "Any mortality during reservation period will be the responsibility of the supplier, who will take care for replacement; reduction from the total price can be negotiated or IZS has the right to cancel the order and all possible payments already paid must be refunded to IZS.",
            "In case of mortality after arrival, certificates by veterinary official will be provided within 24 hours"           
        ]
    },
    {
        "title": "Before shipment",
        "conditions":[
            "All export documents (certificates, permits, tests and others) related to this transaction should be received within 5 working days prior day of shipment, in order to be duly approved by corresponding authorities in the country of final destination."
        ]
    }		
]
export const esClientConditions = [
    {
        "title": "Condiciones Generales",
        "conditions":[
            "Es necesario acordar el tiempo de entrega",
            "Posibilidad de envíos parciales",
            "IZS acordó con la institución proveedora mantener los animales en cuestión reservados para el cliente durante el procedimiento de permiso. IZS no puede aceptar ninguna responsabilidad en caso de que el proveedor, debido a ciertas circunstancias, no pueda suministrar los especímenes a tiempo, en ese caso se fijará un nuevo plazo de entrega. acordado.",
            "El pago del depósito sólo es reembolsable en caso de que el proveedor/IZS no pueda cumplir con la entrega según lo acordado.",
            "Cualquier imperfección debe mencionarse dentro de las 24 horas posteriores a la llegada al aeropuerto de destino.",
            "En caso de mortalidad, se requiere un certificado emitido por un funcionario veterinario dentro de las 24 horas posteriores a la llegada. Los cadáveres deben almacenarse en un congelador hasta que se haya realizado la restitución del pago.",
            "Antes de aceptar esta reserva, el receptor ha investigado que se pueden cumplir todas las condiciones veterinarias para la importación de las especies en cuestión.",
            "Debido a Covid-19, pandemias, situaciones políticas, etc., se pueden esperar cambios en los procesos logísticos. Pueden producirse retrasos y cancelaciones de vuelos/barcos/camiones. Las tarifas pueden fluctuar cada hora. Si el vuelo no puede continuar por algún motivo , los costos de la cuarentena/establo, las pruebas y otros costos corren por cuenta del solicitante. IZS no es responsable de ningún costo debido a las razones mencionadas anteriormente."
        ]
    },
    {
        "title": "Condiciones de pago",
        "conditions":[
            "Especies que no necesitan documentos especiales y pueden ser enviadas inmediatamente: pago completo al momento de la reserva. Especies que necesitan solicitud de permiso: 30% al momento de la reserva; el resto antes del envío. Adjunta encontrará nuestra factura. Su La reserva se considerará definitiva una vez recibido el pago."
        ]
    },
    {
        "title": "Solicitud de permisos",
        "conditions":[
            "En caso de que se requiera un permiso CITES de exportación, la solicitud del permiso CITES se iniciará tan pronto como se reciba el pago del depósito.",
            "Si todos los permisos se conceden a ambas partes, pero el cliente necesita retrasar el envío, todos los posibles costes adicionales correrán a cargo del cliente."
        ]
    },
    {
        "title": "Antes del envío",
        "conditions":[
            "Las pruebas requeridas para la emisión del Certificado de Salud Veterinaria tienen un costo adicional, a menos que se acuerde lo contrario. Todos los documentos de exportación relacionados con esta transacción serán presentados por la institución proveedora para su aprobación, 4 días antes del envío.",
            "Por favor, infórmenos a su regreso si cumplen con los requisitos correspondientes de las autoridades del país de destino final de la especie."
        ]
    },
			
]

export const esSupplierConditions = [
    {
        "title": "Condiciones Generales",
        "conditions":[
            "El tiempo de entrega debe ser acordado.",
            "Si el proveedor no puede enviar los animales antes de que finalice el plazo de entrega, IZS tiene derecho a cancelar el pedido o se puede considerar un retraso en el envío, con la condición de que se deduzca un porcentaje del 2% del precio de coste por cada semana de retraso. desde la factura final, con un máximo de 2 meses de retraso total.",
            "El proveedor informará a IZS sobre cualquier defecto estético, esterilización o incompatibilidad antes de confirmar la reserva.",
            "Cualquier mortalidad durante el período de reserva será responsabilidad del proveedor, que se hará cargo de la reposición; se puede negociar una reducción del precio total o IZS tiene derecho a cancelar el pedido y todos los posibles pagos ya pagados deberán ser reembolsados ​​a IZS. ",
            "En caso de mortalidad después de la llegada, los certificados del veterinario oficial se entregarán dentro de las 24 horas siguientes."
        ]
    },
    {
        "title": "Antes del envío",
        "conditions":[
            "Todos los documentos de exportación (certificados, permisos, pruebas y otros) relacionados con esta transacción deberán recibirse dentro de los 5 días hábiles anteriores al día del embarque, para ser debidamente aprobados por las autoridades correspondientes del país de destino final."
        ]
    }		
]
   