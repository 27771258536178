import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'

import PageHeader from '../components/PageHeader'
import Row from '../components/Row'
import Column from '../components/Column'
import Loader from '../components/Loader'

import {speciesMenuSkeleton} from '../data/speciesMenuSkeleton'
import {en, es} from '../languages/langs'
import {api, base} from '../services/api'
import {primates_links} from '../data/PrimatesInfo'

const MiniCatalog = ({activeLang, orderNames, route, updateActiveRoute}) => {



	const [pageHeaderInfo, setPageHeaderInfo] = useState({"icon": "", "title": "", "bg": ""})

	
	const slug = window.location.pathname.substring(1)
	const slugParts = slug.split('/')



	const getInfoText = () => {
		if (activeLang === 'en') {
			return 'There are several ' + slugParts[1] + ' available of the different families. To download complete list of this category or for more information please register/log in using above links'
		} else {
			return 'There are several ' + slugParts[1] + ' available of the different families. To download complete list of this category or for more information please register/log in using above links'
		}
	}

	

	useEffect(() => {		
		const slug = window.location.pathname.substring(1)
		const slugParts = slug.split('/')
		
		const menuItem = speciesMenuSkeleton.find(item => item.apiName === slugParts[0])
		let thisEntry = {}

		const renderPageHeader = async () => {
			if (orderNames[slugParts[1]] === undefined) {
				const response = await api.get(`/get-class-menu?class=${slugParts[0]}`)
				const entries = response.data
				thisEntry = entries.find(entry => {
					return entry.common_name_slug === slugParts[1]
				})
			} else {
				thisEntry = orderNames[slugParts[1]]
			}
			thisEntry = thisEntry === undefined ? menuItem : thisEntry

			setPageHeaderInfo({
				"className": activeLang === 'en' ? menuItem.menuTitle : menuItem.menuTitleEs,
				"title": activeLang === 'en' ? thisEntry.common_name || thisEntry.menuTitle : thisEntry.common_name_spanish || thisEntry.menuTitleEs,
				"icon": slugParts[0],
				"bg": menuItem.bg,
				"classLink": slugParts[0]
			})
		}
		renderPageHeader()
	}, [activeLang, route, orderNames])



	const relatedLinks = primates_links.map((category, index) => {
		const title = activeLang === 'en' ? category.title : category.spanishTitle
		const subLinks = category.links.map(subcategory => {
				return (
					<Column extraClasses='one-image'>

						<Link to={{pathname : subcategory.web_url}} target="_blank" className="image-button">
								<div className="square-container image-button__container">
									<div
										className="square-container image-button__img-container"
										style={{backgroundImage: `url('${subcategory.ipath}')`}}
									>
									</div>
									<div className="image-button__img-arrow-container green-background"></div>
								</div>
							</Link>




					</Column>
				)
		})
		return (
				<Column extraClasses="related-info-div">
					<p className='category-title white-text'>{title}</p>
					<div className='image-row'>
						{subLinks}
					</div>
				</Column>
		)
		
	})



	return (
		<React.Fragment>
			
			<PageHeader
				showTitle={false}
				image={`https://${base}/header_images${window.location.pathname}.jpg`}
				bgPos="50% 50%"
				highVersion={true}
				extraClasses="content-width center p-left__x-large"
			>
				<div className="page-image-header__title content-width center p-top__x-large text-shadow--large">
					{/* small icon */}
					<div className="page-image-header__title-icon">
						
						<i className={`icon ${pageHeaderInfo.icon}`}></i>
						
					</div>

					<h1 className="creme-text m-bottom__none">{pageHeaderInfo.title}</h1> 
				</div>
				<div className="content-width center page-image-header__breadcrumbs m-top__x-large creme-text p-bottom__large text-shadow">
					<Link to="/" className="page-image-header__breadcrumb creme-text">Home</Link>
					<span className="page-image-header__breadcrumb-sep">&gt;&gt;</span>
					<Link
						to={`/${pageHeaderInfo.classLink}`}
						className="page-image-header__breadcrumb creme-text"
						onClick={() => {updateActiveRoute(pageHeaderInfo.classLink)}}
					>
							{pageHeaderInfo.className}
					</Link>
					{window.location.pathname.substring(1).split('/').length === 2 &&
						<React.Fragment>
							<span className="page-image-header__breadcrumb-sep">&gt;&gt;</span>
							<span
								className="page-image-header__breadcrumb creme-text"
							>
									{pageHeaderInfo.title}
							</span>
						</React.Fragment>
					}
				</div>
			</PageHeader>
			{/*top text */}
            <p className='stuffed-text content-width center'>
                <span dangerouslySetInnerHTML={{__html: getInfoText()}} />
            </p> 
			{/*related links */}
			<Row extraClasses="image-section p-bottom__large content-width center mobile-layout--hor-scroll">
					{relatedLinks }
			</Row>	
			
		</React.Fragment>
	)
}

export default MiniCatalog
