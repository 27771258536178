import React, {useState} from 'react'

import PageHeader from '../components/PageHeader'
import Section from '../components/Section'
import Row from '../components/Row'
import Column from '../components/Column'
import SummaryTile from '../components/SummaryTile'
import Button from '../components/Button'
import Modal from '../components/Modal'
import Checkbox from '../components/forms/Checkbox'

import {en, es} from '../languages/langs'
import {countryCodes} from '../data/countryCodes'
import {api} from '../services/api'
import { useHistory } from 'react-router-dom'

const Register = ({activeLang}) => {
	const lang = {en, es}[activeLang]
	const [submit, setSubmit] = useState(false)
	const history = useHistory()

	function feedModal(state) {
		let m = document.getElementById('modal2')
		switch(state){
			case 'close':
				m.style.display = 'none';
				break
			case 'open': 
			    m.style.display = 'block';
				break
			default:
			    break
		}
    }  

	const renderedCountrySelect = []
	for (let code in countryCodes) {
		renderedCountrySelect.push(<option key={code} value={code}>{countryCodes[code]}</option>)
	}

	function isValidURL(string) {
        var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
        return (res !== null)
    };

	function checkAllInterestSections() {
		if (document.getElementById('interest-zoo-animals').checked) {
			document.getElementById('interest-birds').checked = true
			document.getElementById('interest-aquatic').checked = true
			document.getElementById('interest-primates').checked = true
			document.getElementById('interest-small-mammals').checked = true
			document.getElementById('interest-reptiles').checked = true
			document.getElementById('interest-carnivores').checked = true
		} else {
			document.getElementById('interest-birds').checked = false
			document.getElementById('interest-aquatic').checked = false
			document.getElementById('interest-primates').checked = false
			document.getElementById('interest-small-mammals').checked = false
			document.getElementById('interest-reptiles').checked = false
			document.getElementById('interest-carnivores').checked = false
		}
	}

	function gotoNextRegisterPage(no) {
		let focusedPage = no - 1
		
		const country         = [...document.getElementById('register-form__country').children].filter(opt => opt.selected)[0].value
		const institution     = [...document.getElementById('register-form__institution').children].filter(opt => opt.selected)[0].value
		const website         = document.getElementById('register-form--website').value.trim()
		const facebookPage    = document.getElementById('register-form--fbpage').value.trim()

		const fields          = [...document.getElementById(`register-form__part${focusedPage}`).getElementsByTagName('INPUT')].map(input => {
			return {
				"type"      : input.type,
				"mandatory" : input.classList.contains('register-form__input--mandatory'),
				"name"      : input.id,
				"checked"   : input.checked,
				"ref"       : input
			}
		});
		const noWebsiteModalFields = [...document.getElementById('modal').getElementsByTagName('TEXTAREA')].map(input => {
			return {
				"type": input.type,
				"mandatory": input.classList.contains('register-form__input--mandatory'),
				"name": input.id,
				"checked": input.checked,
				"ref": input
			}
		});
		[...document.getElementById('modal').getElementsByTagName('INPUT')].forEach(ta => {
			noWebsiteModalFields.push({
				"type": "text",
				"name": ta.name,
				"mandatory": false,
				"ref": ta
			})
		});

		if (focusedPage === 1) {
			if (country === 'empty') {
				document.getElementById('register-form__country').classList.add('register-form__input--has-error')
			}
			else {
				document.getElementById('register-form__country').classList.remove('register-form__input--has-error')
			}
		}
		else if (focusedPage == 2) {
			if (institution === 'empty') {
				document.getElementById('register-form__institution').classList.add('register-form__input--has-error')
			}
			else {
				document.getElementById('register-form__institution').classList.remove('register-form__input--has-error')
			}
		}
		
		const errorFields = fields.filter(field => {
			if ((field.type === 'text' || field.type === 'password') && field.mandatory && field.ref.value === '') {
				field.ref.classList.add('register-form__input--has-error')
				return field
			} else {
				field.ref.classList.remove('register-form__input--has-error')
			}

			if (field.type === 'email'
				&& field.mandatory
				&& (field.ref.value === ''
					|| !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(field.ref.value)
				)
			) {
				field.ref.classList.add('register-form__input--has-error')
				return field
			} else {
				field.ref.classList.remove('register-form__input--has-error')
			}
		})

		if (errorFields.length > 0 || (focusedPage === 1 && country === 'empty') || (focusedPage === 2 && institution === 'empty')) {
			alert(lang['mand_info_missing'])
			return
		}
		if (focusedPage === 1) {
			const pfield = fields.find(field => field.name === 'register-form--password')
			const rpfield = fields.find(field => field.name === 'register-form--rpassword')
			if (pfield.ref.value !== rpfield.ref.value) {
				alert(lang['passwd_mismatch'])
				return
			}
		}
		else if (focusedPage === 2) { 
			if ((website !== '' && !isValidURL(website)) || (facebookPage !== '' && !isValidURL(facebookPage))) {
				alert(lang['website_wrong_url'])
				return;
			}
			
			if (website === '' && facebookPage === '' && !document.getElementById('no-website').checked) {
				alert(lang['extra_info_required'])
				return;
			}
		}

		[...document.getElementsByClassName('register-form__part')].forEach(part => {
			part.classList.add('inactive')
			part.classList.remove('active')
		});
		[...document.getElementsByClassName('progress-pill')].forEach(pill => pill.classList.remove('progress-pill--active'))
		document.getElementById(`register-form__part${no}`).classList.add('active')
		document.getElementById(`register-form__part${no}`).classList.remove('inactive')
		document.getElementById(`progress-pill${no}`).classList.add('progress-pill--active')
		
		let ppill1 = document.getElementById('progress-pill1'),
			mLeft = 0;
		for (let i = 1; i < no; i++) {
			let node = document.getElementById('progress-pill' + i),
				w = node.getBoundingClientRect().width,
				m = parseFloat(getComputedStyle(node).marginRight);
				mLeft += (w + m)
		}
		ppill1.style.marginLeft = '-' + mLeft + 'px';
	}

	function gotoBackRegisterPage(no) {
		[...document.getElementsByClassName('register-form__part')].forEach(part => {
			part.classList.add('inactive')
			part.classList.remove('active')
		});
		[...document.getElementsByClassName('progress-pill')].forEach(pill => pill.classList.remove('progress-pill--active'))
		document.getElementById(`register-form__part${no}`).classList.add('active')
		document.getElementById(`register-form__part${no}`).classList.remove('inactive')
		document.getElementById(`progress-pill${no}`).classList.add('progress-pill--active')
		
		let ppill1 = document.getElementById('progress-pill1'),
			mLeft = 0;
		for (let i = 1; i < no; i++) {
			let node = document.getElementById('progress-pill' + i),
				w = node.getBoundingClientRect().width,
				m = parseFloat(getComputedStyle(node).marginRight);
				mLeft += (w + m)
		}
		ppill1.style.marginLeft = '-' + mLeft + 'px';
	}

	function modal(state) {
		let m = document.getElementById('modal')

		if (state === 'close') {
            const textAreasDescriptionValues = [...m.getElementsByTagName('textarea')]
			const re = new RegExp(/^(?=(?:\s*\S){15,})\s*\S/)
			let has_errors = false
			
			textAreasDescriptionValues.forEach((textarea) => {
				if (!re.test(textarea.value)) {
					textarea.classList.add('has-error')
					has_errors = true
				} else {
					textarea.classList.remove('has-error')
				}
			})

			m.style.display = 'none';
			gotoNextRegisterPage(3);
			
		}
		else if (state === 'hide') {
			m.style.display = 'none';
		}
		else
			m.style.display = 'block'
	}

	async function doSend() {
		const salutation = [...document.getElementById('register-form__salutation').children].filter(opt => opt.selected)[0].value
		const institution = [...document.getElementById('register-form__institution').children].filter(opt => opt.selected)[0].value
		const country = [...document.getElementById('register-form__country').children].filter(opt => opt.selected)[0].value
		const fields = [...document.getElementById('register-form').getElementsByTagName('INPUT')].map(input => {
			return {
				"type": input.type,
				"mandatory": input.classList.contains('register-form__input--mandatory'),
				"name": input.id,
				"checked": input.checked,
				"ref": input
			}
		});
		[...document.getElementById('modal').getElementsByTagName('TEXTAREA')].forEach(ta => {
			fields.push({
				"type": "text",
				"name": ta.name,
				"mandatory": false,
				"ref": ta
			})
		});
		[...document.getElementById('modal').getElementsByTagName('INPUT')].forEach(ta => {
			fields.push({
				"type": "text",
				"name": ta.name,
				"mandatory": false,
				"ref": ta
			})
		});
		const errorFields = fields.filter(field => {
			if ((field.type === 'text' || field.type === 'password') && field.mandatory && field.ref.value === '') {
				field.ref.classList.add('register-form__input--has-error')
				return field
			} else {
				field.ref.classList.remove('register-form__input--has-error')
			}

			if (field.type === 'email'
				&& field.mandatory
				&& (field.ref.value === ''
					|| !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(field.ref.value)
					)
			) {
				field.ref.classList.add('register-form__input--has-error')
				return field
			} else {
				field.ref.classList.remove('register-form__input--has-error')
			}
		})
		if (errorFields.length > 0) {
			/*gotoBackRegisterPage(1)*/
			alert(lang['mand_info_missing'])
			return
		}
		const interestSectionsMarkedFields = [];
		[...document.getElementById('register-form').getElementsByClassName('interest-checkbox')].forEach(field => {
			[...field.getElementsByTagName('INPUT')].forEach(input => {
				if (input.checked) {
					interestSectionsMarkedFields.push({
						"type": input.type,
						"name": input.id,
						"checked": input.checked,
						"ref": input
					})
				}
			});
		})
		if (interestSectionsMarkedFields.length === 0) {
			alert(lang['interest_section_info_required'])
			return
		}
		if (!document.getElementById('like-to-receive-surplus').checked && !document.getElementById('do-not-receive-surplus').checked) {
			alert(lang['newsletters_info_required'])
			return
		}

		const pfield      = fields.find(field => field.name === 'register-form--password');
		const rpfield     = fields.find(field => field.name === 'register-form--rpassword');
		const application = {
			"email"                     : fields.find(field => field.name === 'register-form--email').ref.value,
			"password"                  : pfield.ref.value,
			"first_name"                : fields.find(field => field.name === 'register-form--fname').ref.value,
			"last_name"                 : fields.find(field => field.name === 'register-form--lname').ref.value,
			"title"                     : salutation,
			"position"                  : fields.find(field => field.name === 'register-form--position').ref.value,
			"country"                   : country,
			"city"                      : fields.find(field => field.name === 'register-form--city').ref.value,
			"organisation"              : {
				"type"                  : institution,
				"name"                  : fields.find(field => field.name === 'register-form--nameofinstitution').ref.value
			},
			"phone"                     : fields.find(field => field.name === 'register-form--phone').ref.value,
			"facebook_page"             : fields.find(field => field.name === 'register-form--fbpage').ref.value,
			"mobile_phone"              : fields.find(field => field.name === 'register-form--whatsapp').ref.value,
			"interests"                 : {
				"zoo_animals"           : fields.find(field => field.name === 'interest-zoo-animals').checked,
				"birds"                 : fields.find(field => field.name === 'interest-birds').checked,
				"aquatic_species"       : fields.find(field => field.name === 'interest-aquatic').checked,
				"primates"              : fields.find(field => field.name === 'interest-primates').checked,
				"small_mammals"         : fields.find(field => field.name === 'interest-small-mammals').checked,
				"reptiles"              : fields.find(field => field.name === 'interest-reptiles').checked,
				"carnivores"            : fields.find(field => field.name === 'interest-carnivores').checked,
			},
			"receive_surplus_wanted"    : fields.find(field => field.name === 'like-to-receive-surplus').checked
		};

		try {
		    const response = await api.post(
			    '/register',
			    application
		    )
			if (response.data.success === false) {
				alert(response.data.message)
				return
			}
			setSubmit(true)
			feedModal('open')
		} catch (e) {
			console.log(e)
			feedModal('open')
		}
	}

	async function sendRegistrationForm() {
		// Do Google Captcha check and send if score is above 0.5
		window.grecaptcha.ready(async function() {
			const token = await window.grecaptcha.execute('6Lfdy2UeAAAAALZqpWcDyZwI_j3nbklS5wRpmjHC', {action: 'submit'})
			const response = await fetch('https://cors.zoo-services.com/https://www.google.com/recaptcha/api/siteverify', {
				method: 'POST',
				body: `secret=6Lfdy2UeAAAAAKPp-OvkmrJjYo8b4cY1Fgn75pyJ&response=${token}`,
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				}
			})
			try {
				const result = await response.json()
				if (result.score > 0.5) {
					doSend()
				}
			} catch (e) {
				console.error(e)
			}
		});
	}

	return (
		<React.Fragment>
			<PageHeader titleKey="Register" image="images/tiger-john2.jpg" bgPos="60% 50%" activeLang={activeLang} />
			<Section extraClasses="content-width center">
				<Row extraClasses="mobile-p-left__large mobile-p-right__large">
					<Column extraClasses="size__two-of-three m-top__x-large">
						<Row extraClasses="progress-pills">
							<div id="progress-pill1" style={{marginLeft: 0}} className="progress-pill m-right__x-large progress-pill--has-next progress-pill--active column row mobile-column--autowidth mobile-float__none">
								<div className="progress-pill__counter column browngrey-background white-text mobile-column--autowidth">1</div>
								<div className="progress-pill__text column browngrey-text mobile-column--autowidth">{lang['About you']}</div>
							</div>
							<div id="progress-pill2" className="progress-pill m-right__x-large progress-pill--has-next column row mobile-column--autowidth mobile-float__none">
								<div className="progress-pill__counter column browngrey-background white-text mobile-column--autowidth">2</div>
								<div className="progress-pill__text column browngrey-text mobile-column--autowidth">{lang['About your institution']}</div>
							</div>
							<div id="progress-pill3" className="progress-pill column row mobile-column--autowidth mobile-float__none">
								<div className="progress-pill__counter column browngrey-background white-text mobile-column--autowidth">3</div>
								<div className="progress-pill__text column browngrey-text mobile-column--autowidth">{lang['Details of the interest']}</div>
							</div>
						</Row>
						<Row extraClasses="m-top__large">
							<Column extraClasses="size__one-of-one register-form" id="register-form">
								<SummaryTile
									id="register-form__part1"
									titleKey="Personal information"
									extraClasses="m-top__x-large register-form__part p-right__medium p-bottom__large"
									subTitleKey="About you"
									buttonOnClick={() => gotoNextRegisterPage(2)}
									buttonTextKey="Next"
									activeLang={activeLang}
								>
									<div className="summary-tile__content m-top__x-large">
										<Row>
											<Column extraClasses="size__two-of-twelve">
												<select name="register-form__salutation" id="register-form__salutation" className="register-form__salutation select--creme">
													<option value="Mr.">Mr.</option>
													<option value="Mrs.">Mrs.</option>
													<option value="Ms.">Ms.</option>
													<option value="Dr.">Dr.</option>
													<option value="Ing.">Ing.</option>
												</select>
											</Column>
											<Column extraClasses="size__five-of-twelve p-left__medium mobile-p-left__none mobile-m-top__medium">
												<input
													type="text"
													className="register-form__input register-form__input--mandatory"
													placeholder={`${lang['First Name']}*`}
													id="register-form--fname"
												/>
											</Column>
											<Column extraClasses="column size__five-of-twelve p-left__medium mobile-p-left__none mobile-m-top__medium">
												<input
													type="text"
													className="register-form__input register-form__input--mandatory"
													placeholder={`${lang['Last Name']}*`}
													id="register-form--lname"
												/>
											</Column>
										</Row>
										<Row extraClasses="m-top__medium">
											<Column extraClasses="size__one-of-two">
												<select name="register-form__country" id="register-form__country" className="register-form__country select--creme">
													<option key="empty" value="empty">{`${lang['Country']}*`}</option>
													{renderedCountrySelect}
												</select>
											</Column>
											<Column extraClasses="size__one-of-two p-left__medium mobile-p-left__none mobile-m-top__medium">
												<input
													type="text" 
													className="register-form__input register-form__input--mandatory"
													placeholder={`${lang['City']}*`}
													id="register-form--city"
												/>
											</Column>
										</Row>
										<Row extraClasses="m-top__medium">
											<Column extraClasses="size__one-of-two">
												<input
													type="email"
													className="register-form__input register-form__input--mandatory"
													placeholder={`${lang['Email address']}*`}
													id="register-form--email"
												/>
											</Column>
											<Column extraClasses="size__one-of-two p-left__medium mobile-p-left__none mobile-m-top__medium">
												<input
													type="password"
													className="register-form__input register-form__input--mandatory"
													placeholder={`${lang['Create password']}*`}
													id="register-form--password"
												/>
											</Column>
										</Row>
										<Row extraClasses="m-top__medium">
											<Column extraClasses="size__one-of-two">
												<input
													type="text" 
													className="register-form__input"
													placeholder="Whatsapp"
													id="register-form--whatsapp"
												/>
											</Column>
											<Column extraClasses="size__one-of-two p-left__medium mobile-p-left__none mobile-m-top__medium">
												<input
													type="password" 
													className="register-form__input register-form__input--mandatory"
													placeholder={`${lang['Repeat Password']}*`}
													id="register-form--rpassword"
												/>
											</Column>
										</Row>
										<Row extraClasses="m-top__large"></Row> {/* Just to space the button down */}
									</div>
								</SummaryTile>
								<SummaryTile
									id="register-form__part2"
									titleKey="Corporate information"
									extraClasses="m-top__x-large register-form__part p-right__medium p-bottom__large"
									subTitleKey="About your institution"
									showButton={false}
									activeLang={activeLang}
								>
									<div className="summary-tile__content m-top__x-large">
										<Row extraClasses="m-top__medium">
											<Column extraClasses="size__one-of-two">
												<select name="register-form__institution" id="register-form__institution" className="register-form__institution type select--creme" defaultValue="Type of institution">
													<option value="empty">{`${lang['Type of insitution']}*`}</option>
													<option value="PBF">{lang['Private breeding farm']}</option>
													<option value="AS">{lang['Animal supplier']}</option>
													<option value="Z">{lang['Zoo and public facilities']}</option>
													<option value="ACON">{lang['Animal consult and vet']}</option>
												</select>
											</Column>
											<Column extraClasses="size__one-of-two p-left__medium mobile-p-left__none mobile-m-top__medium">
												<input
													type="text" 
													className="register-form__input register-form__input--mandatory"
													placeholder={`${lang['Name of insitution']}*`}
													id="register-form--nameofinstitution"
												/>
											</Column>
										</Row>
										<Row>
											<Column extraClasses="size__one-of-one text--right text--small" id='private-info-notice'>
												({lang['In case you are a private person']})
											</Column>
										</Row>
										<Row extraClasses="m-top__medium">
											<Column extraClasses="size__one-of-two">
												<input
													type="text"
													className="register-form__input"
													placeholder={lang['Phone']}
													id="register-form--phone"
												/>
											</Column>
											<Column extraClasses="size__one-of-two p-left__medium mobile-p-left__none mobile-m-top__medium">
												<input
													type="text" 
													className="register-form__input"
													placeholder={lang['Your position']}
													id="register-form--position"
												/>
											</Column>
										</Row>
										<Row extraClasses="m-top__medium">
											<Column extraClasses="size__one-of-two">
												<input
													type="text"
													className="register-form__input"
													placeholder={lang['Website']}
													id="register-form--website"
												/>
											</Column>
											<Column extraClasses="size__one-of-two p-left__medium mobile-p-left__none mobile-m-top__medium">
												<input
													type="text" 
													className="register-form__input"
													placeholder={lang['Facebook page']}
													id="register-form--fbpage"
												/>
											</Column>
										</Row>
										<Row extraClasses="m-top__medium">
											<Column extraClasses="size__one-of-two">
												<label htmlFor="no-website">
													<input
														type="checkbox"
														className="register-form__input"
														name="no-website"
														id="no-website"
													/>
													<span>{lang['No Website']}</span>
												</label>
											</Column>
										</Row>
										<Row extraClasses="m-top__large">
											<Button onClick={() => gotoBackRegisterPage(1)} extraClasses="button--back m-top__large m-right__small">{en['Previous']}</Button>
											<Button 
												onClick={() => {
													if(document.getElementById('no-website').checked) {
														modal('open')
													} else {
														gotoNextRegisterPage(3)
													}
												}}
												extraClasses="m-top__large">{en['Next']}</Button>
										</Row>
									</div>
								</SummaryTile>
								<SummaryTile
									id="register-form__part3"
									titleKey="Details of the interest"
									extraClasses="m-top__x-large register-form__part p-right__medium p-bottom__large"
									subTitleKey="Sections of interest (select the sections you are interested in)"
									showButton={false}
									activeLang={activeLang}
								>
									<div className="summary-tile__content m-top__x-large">
										<Row extraClasses="m-top__medium">
											<Column extraClasses="size__one-of-two">
												<Checkbox
													activeLang={activeLang}
													extraClasses="interest-checkbox"
													id="interest-zoo-animals"
													labelKey="Zoo animals (all categories, mammals, birds, reptiles, fishes, etc.)"
													onClick={() => checkAllInterestSections()}
												/>
											</Column>
											<Column extraClasses="size__one-of-two">
												<Checkbox
													activeLang={activeLang}
													extraClasses="interest-checkbox"
													id="interest-small-mammals"
													labelKey="Small mammals (marmosets, kangaroos, rodents, etc.)"
												/>
											</Column>
										</Row>
										<Row extraClasses="m-top__medium">
											<Column extraClasses="size__one-of-two">
												<Checkbox
													activeLang={activeLang}
													extraClasses="interest-checkbox"
													id="interest-aquatic"
													labelKey="Aquatic species (Sealions, otters, pengiuns, sharks, etc)"
												/>
											</Column>
											<Column extraClasses="size__one-of-two">
												<Checkbox
													activeLang={activeLang}
													extraClasses="interest-checkbox"
													id="interest-primates"
													labelKey="Primates"
												/>
											</Column>
										</Row>
										<Row extraClasses="m-top__medium">
											<Column extraClasses="size__one-of-two">
												<Checkbox
													activeLang={activeLang}
													extraClasses="interest-checkbox"
													id="interest-birds"
													labelKey="Birds (all species of birds)"
												/>
											</Column>
											<Column extraClasses="size__one-of-two">
												<Checkbox
													activeLang={activeLang}
													extraClasses="interest-checkbox"
													id="interest-reptiles"
													labelKey="Reptiles, Amphibians, Fishes, others"
												/>
											</Column>
										</Row>
										<Row extraClasses="m-top__medium">
											<Column extraClasses="size__one-of-two">
												<Checkbox
													activeLang={activeLang}
													extraClasses="interest-checkbox"
													id="interest-carnivores"
													labelKey="Carnivores"
												/>
											</Column>
											<Column extraClasses="size__one-of-two">
											</Column>
										</Row>
										<Row extraClasses="m-top__medium m-bottom__medium">
											<span className="sub-title sub-title--follows-h3 browngrey-text">
												{lang['Information about available and wanted species']}
											</span>
										</Row>
										<Row extraClasses="m-top__medium">
											<Column extraClasses="size__one-of-one">
												<Checkbox
													activeLang={activeLang}
													id="like-to-receive-surplus"
													labelKey="register_would_like_to_receive_surplus"
													boxColumnSize="small"
												/>
											</Column>
											<Column extraClasses="size__one-of-one">
												<Checkbox
													activeLang={activeLang}
													id="do-not-receive-surplus"
													labelKey="register_do_not_want_to_receive_surplus"
													boxColumnSize="small"
													extraClasses="m-top__medium"
												/>
											</Column>
										</Row>
										<Row extraClasses="m-top__large">
											<Button onClick={() => gotoBackRegisterPage(2)} extraClasses="button--back m-top__large m-right__small">{en['Previous']}</Button>
											<Button onClick={() => sendRegistrationForm()} extraClasses="m-top__large">{lang['Submit']}</Button>
										</Row>
									</div>
								</SummaryTile>
							</Column>
						</Row>
					</Column>
					<Column extraClasses="size__one-of-three p-left__medium mobile-p-right__none mobile-p-left__none">
						<div className="framed-image framed-image--xx-largeheight m-top__medium pull-up--mediumsmall pull-up--mobile-none">
							<div className="framed-image__frame">
								<div className="framed-image__image" style={{backgroundImage: "url('images/koala.jpg')"}}></div>
							</div>
						</div>
					</Column>
				</Row>
				<Modal extraId={''}>
					<h4 className="browngray-text m-top__none">{lang['More information']}</h4>
						<h5 className="green-text">
							{lang['register_page___describe_your_institution']}<br />
							<small>{lang['register_page_header_note']}</small>
						</h5>
						<textarea name="full_job_desc" id="shortdesc" cols="30" rows="12" maxLength={2000} placeholder={lang['register_page___describe_your_institution_textarea']}></textarea>
						<Button onClick={() => {modal('close')}} extraClasses="m-top__medium m-right__small">{lang['Submit']}</Button>
						<Button onClick={() => {modal('hide')}} extraClasses="m-top__medium">{lang['Close']}</Button>
				</Modal>
				<Modal extraId={'2'}>
				{submit ?
					<>
					   <h3 className="browngray-text m-top__none" >{lang['Registered']}</h3>
					   <h4>{lang['approval']}</h4>
					   <h4>{lang['Check email']}</h4>					   
					   <Button onClick={() => history.push('/')} extraClasses="m-top__medium m-left__medium">{lang['Home']}</Button>
					</> : 
					<>
					    <h3 className="browngray-text m-top__none" >{lang['Sorry']}</h3>
					    <h4>{lang['Error sending']}</h4>
					    <h4>{lang['Support']}</h4>
					    <h4>{lang['Whatsapp']}</h4>
					    <h4>Email: <a href=' info@zoo-services.com' target="_blank"> info@zoo-services.com</a></h4>
					    <Button onClick={() => {feedModal('close')}} extraClasses="m-top__medium m-right__small">{lang['Close']}</Button>
					    <Button onClick={() => history.push('/')} extraClasses="m-top__medium m-left__medium">{lang['Home']}</Button>
					</>					
				    }
				</Modal>
			</Section>
			<Section id="be_user" extraClasses="creme-background m-top__xx-large p-top__x-large p-bottom__x-large mobile-p-left__large mobile-p-right__large">
				<Row extraClasses="center content-width">
					<Column extraClasses="size__one-of-one text-columns--2">
						<SummaryTile
							extraClasses="keep-together"
							titleKey="Can anyone be a user"
							subTitleKey="of our databank of International Zoo Services (IZS)?"
							showButton={false}
							activeLang={activeLang}
						>
							<div className="summary-tile__content line-height__large m-top__large m-bottom__large">
								{lang['register_q_1']}
							</div>
						</SummaryTile>
						<SummaryTile
							extraClasses="keep-together"
							titleKey="After registration"
							subTitleKey="will I automatically be a user of the databank?"
							showButton={false}
							activeLang={activeLang}
						>
							<div className="summary-tile__content line-height__large m-top__large m-bottom__large">
								{lang['register_q_2']}
							</div>
						</SummaryTile>
						<SummaryTile
							extraClasses="keep-together"
							titleKey="Do other users get entrance"
							subTitleKey="to my details?"
							showButton={false}
							activeLang={activeLang}
						>
							<div className="summary-tile__content line-height__large m-top__large m-bottom__large">
								{lang['register_q_3']}
							</div>
						</SummaryTile>
						<SummaryTile
							extraClasses="keep-together"
							titleKey="Why so many"
							subTitleKey="questions?"
							showButton={false}
							activeLang={activeLang}
						>
							<div className="summary-tile__content line-height__large m-top__large m-bottom__large">
								{lang['register_q_4']}
							</div>
						</SummaryTile>
					</Column>
				</Row>
			</Section>
		</React.Fragment>
	)
}

export default Register