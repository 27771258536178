import React from 'react'


const ImageWithTitle = props => {
    
    const getText = () => {
        if (props.activeLang === 'en') {
            return props.text
        } else {
            return props.spanishText
        }
    }    



    return (
        <div className="image-button" >

            <div className="square-container image-button__container">
                <div
                    className="square-container image-button__img-container"
                    style={{backgroundImage: `url('${props.image}')`}}
                >
                </div>
            
            </div>
          
            <span className="image-button__text-container p-top__large mobile-p-top__x-large">
                {getText()}
            </span>
        </div>
    )
}

export default ImageWithTitle