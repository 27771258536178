import React from 'react'
import Row from '../components/Row'
import Column from '../components/Column'

const DelConditions = props => {

    const delConditions = props.conditions.map(rules => {
        return(
            <div>
            
            <Row extraClasses="m-bottom__large">
                <Column extraClasses="size__one-of-one creme-background p-top__large p-left__medium p-bottom__large">
                    <h4 className="green-text m-bottom__none">{rules.title}</h4>
                </Column>
            </Row>
            
            {rules.conditions.map(condition => {
                            return (
                                <p className="rules-item line-height__large" dangerouslySetInnerHTML={{__html: condition}}></p>
                            )
                               
                                              
                        }
                    )}
            </div>
        )//one block
    })     

		return (
            <div className='del-rules content-width center'> 
                {delConditions}
            </div>

            
            )
    }
export default DelConditions
