import React from 'react'

import PageHeader from '../components/PageHeader'
import DelConditions from '../components/DelConditions'

import {en, es} from '../languages/langs'
import {clientConditions, esClientConditions} from '../data/deliveryConditions'


const ClientConditions = ({activeLang}) => {
	const lang = {en, es}[activeLang]

	const clientRules = activeLang === 'en' ? clientConditions : esClientConditions




	return (
		<React.Fragment>
			<PageHeader titleKey="Del Rules" image="images/papegaai.jpg" bgPos="0% 50%" activeLang={activeLang} />		
			<DelConditions conditions={clientRules}/>
			</React.Fragment>
	)
}

export default ClientConditions