import React from 'react'
import {stuffedAnimalsList} from '../data/stuffedAnimals'
import ImageWithTitle from '../components/ImageWithTitle'

import PageHeader from '../components/PageHeader'
import Row from '../components/Row'
import Column from '../components/Column'

import {en, es} from '../languages/langs'


const StuffedAnimals = ({activeLang}) => {
    const lang = {en, es}[activeLang]

	const renderedAnimals = stuffedAnimalsList.map((mainEntry, i) => {
		return (
			<Column extraClasses="size__one-of-five p-right__x-large" key={i}>
				<ImageWithTitle 
					image={mainEntry.bg}
					activeLang={activeLang}
					text={mainEntry.menuTitle}
					spanishText={mainEntry.menuTitleEs}
				/>
			</Column>
		)
	
})



	return (
        <React.Fragment>
			
            <PageHeader titleKey="Stuffed Animals" image="images/papegaai.jpg" bgPos="0% 50%" activeLang={activeLang} />		
            <p className='stuffed-text content-width center'>
                <span dangerouslySetInnerHTML={{__html: lang['Stuffed we support']}} />
            </p> 
            <Row extraClasses="p-top__large p-bottom__large content-width center mobile-layout--hor-scroll">
					{renderedAnimals }
			</Row>	
             

        </React.Fragment>
    )
}

export default StuffedAnimals