import React from 'react'

import {en, es} from '../languages/langs'

const CheckAndText = props => {
	const lang = {en, es}[props.activeLang]

	return (
		<p className="check-and-text">
			{lang[props.text]}
		</p>
	)
}

export default CheckAndText