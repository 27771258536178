import React, {useState, useEffect} from 'react'
import {api} from '../services/api'
import { Link } from 'react-router-dom'

import Hero from '../components/Hero'
import Section from '../components/Section'
import Row from '../components/Row'
import Column from '../components/Column'
import Button from '../components/Button'
import ImageButton from '../components/ImageButton'
import ImageText from '../components/ImageText'
import CheckArrowList from '../components/CheckArrowList'
import SummaryTile from '../components/SummaryTile'
import ImageCard from '../components/ImageCard'
import Modal from '../components/Modal'

import checkArrowEntries from '../data/checkArrowEntries'
import {en, es} from '../languages/langs'
import {projects} from '../data/projects'

import {speciesMenuSkeleton} from '../data/speciesMenuSkeleton'

const Home = ({activeLang, loggedIn, updateActiveRoute}) => {
	const lang = {en, es}[activeLang]

	const [firstSixAnimals, setFirstSixAnimals] = useState([])

	const flatProjects = []
	projects.forEach(row => {
		flatProjects.push(...row.cells)
	})

	function shuffleArray(array) {
		for (let i = array.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[array[i], array[j]] = [array[j], array[i]];
		}
	}
	shuffleArray(flatProjects)

	const renderedProjects = flatProjects.map((project, index) => {
		if (index < 4) {
			return (
				<ImageCard
					key={project.title}
					image={project.image}
					titleKey={project.title}
					contentKey={`project_${project.title}`}
					activeLang={activeLang}
				/>
			)
		}
	})
	function toggleSubmenu(evt) {
		var i, submenus, tablinks;
		const rootNode = evt.target.closest("#menu-div");
		var node = rootNode.querySelector(".guest-submenu");
	
		var display_flag = false;
		if (node.style.display === "block") {
			display_flag = false;
		}else {
			display_flag = true;
		}
		submenus = document.getElementsByClassName("guest-submenu");
		for (i = 0; i < submenus.length; i++) {
		  submenus[i].style.display = "none";
		}
		if(display_flag == true ){
			node.style.display = "block"
		}

	}
    function modal(state) {
		let m = document.getElementById('modal1')
		switch(state){
			case 'close':
				m.style.display = 'none';
				break
			case 'open': 
			    m.style.display = 'block';
				break
			default:
			    break
		}
}
	useEffect(() => {
		const getFirstSixAnimals = async () => {
		

			const renderedAnimals =await Promise.all(speciesMenuSkeleton.map(async (mainEntry, i) => {
				const response = await api.get(`/get-class-menu?class=${mainEntry.apiName}`)
				if (i < 5) {
// top 5 picturs	
					if(i==4){
						{/* stuffed animals */}
						return (
							<Column extraClasses="size__one-of-five p-right__x-large" key={i}>
								<ImageButton
									image={mainEntry.bg}
									link={`/${mainEntry.apiName}`}
									activeLang={activeLang}
									text={mainEntry.menuTitle}
									spanishText={mainEntry.menuTitleEs}
									updateActiveRoute={updateActiveRoute}
								/>
							</Column>

						)
					}
					
					if (loggedIn) {
						return (
							<Column extraClasses="size__one-of-five p-right__x-large" key={i}>
								<ImageButton
									image={mainEntry.bg}
									link={`/${mainEntry.apiName}`}
									activeLang={activeLang}
									text={mainEntry.menuTitle}
									spanishText={mainEntry.menuTitleEs}
									updateActiveRoute={updateActiveRoute}
								/>
							</Column>
						)
					} else {
						return (
							<Column extraClasses="size__one-of-five p-right__x-large" key={i}>
								<ImageText 
									onClick={(e)=> toggleSubmenu(e)}
									activeLang={activeLang}
									mainMenu={mainEntry}
									updateActiveRoute={updateActiveRoute}
									subMenu={response.data}
								/>
							</Column>
						)
					}
				}
			
			}))
			//setting top row
			setFirstSixAnimals(renderedAnimals)
		}
		
		getFirstSixAnimals()
	}, [activeLang, updateActiveRoute, loggedIn])

	return (
		<React.Fragment>
			<Hero activeLang={activeLang} />
			<Section extraClasses="row section__content creme-background p-top__xx-large p-bottom__xx-large">
				<Row extraClasses="row content-width center mobile-p-left__large mobile-p-right__large">
					<Column>
						<h3 className="green-text uppercase m-bottom__none">{lang['Species to relocate and special projects']}</h3>
						<span className="sub-title sub-title--follows-h3 browngrey-text">{lang['RELOCATION OF A LARGE VARIETY OF SPECIES']}</span>
					</Column>
					
					{loggedIn ? 
						<Column extraClasses="float-right mobile-display__none">
							<Link to='/all' onClick={() => {updateActiveRoute('/all')}}>
								<span className="button">{lang['See all']}</span>
							</Link>
						</Column> :
						<Column extraClasses="float-right mobile-display__none">						
						    <Button onClick={()=> modal('open')} >{lang['See all']}</Button>
					    </Column>
                    }
				</Row>
				<Modal extraId={'1'}>
					<h3>{lang['Please']}</h3>
					<h4>{lang['Note that']}</h4>
					<h4> {lang['If you are not registered, register'] }<Link to='/register' onClick={() => modal('close')} > {lang["here"]}</Link>.</h4>
					<h4>{lang["If you are registered please"]} <Link to='/login' onClick={() => modal('close')} >{lang["log in"]}</Link>.</h4>
					<Button onClick={() => {modal('close')}} extraClasses="m-top__medium m-right__small center">{lang['Close']}</Button>					
				</Modal>
				<Row extraClasses="content-width center m-top__large mobile-p-left__large mobile-p-right__large mobile-layout--hor-scroll">
					{firstSixAnimals}
				</Row>	
				<Column extraClasses="float-right mobile-display__only mobile-p-left__large mobile-m-top__large">
				{loggedIn ? 						
						<Link to='/all' onClick={() => {updateActiveRoute('/all')}}>
							<span className="button">{lang['See all']}</span>
						</Link>	:											
						<Button onClick={()=> modal('open')} >{lang['See all']}</Button>					    
                    }
				</Column>
			</Section>
			<Section extraClasses="section__content content-width center">
				<Row>
					<Column extraClasses="size__one-of-four overlap-top__medium mobile-p-left__x-large mobile-p-right__x-large">
						<div className="green-background p-top__medium p-bottom__medium p-left__medium p-right__medium">
							<CheckArrowList entries={checkArrowEntries} activeLang={activeLang} />
						</div>
					</Column>
					<Column extraClasses="size__three-of-eight p-left__x-large p-right__large p-top__x-large">
						<SummaryTile
							titleKey="A worldwide network"
							subTitleKey="Contacts with over 15000 institutions"
							textKey="izs_intro"
							buttonTextKey="readmore"
							buttonLink="/aboutus#worldwide-network"
							activeLang={activeLang}
						/>
					</Column>
					<Column extraClasses="size__three-of-eight p-left__x-large p-right__large p-top__x-large">
						<SummaryTile
							titleKey="Transport directly from zoo to zoo"
							subTitleKey="Live animal transport coordinations"
							textKey="transport_text"
							buttonTextKey="readmore"
							buttonLink="/aboutus#transport"
							activeLang={activeLang}
						/>
					</Column>
				</Row>
			</Section>
			<Section extraClasses="section__content-align--left m-top__xx-large mobile-p-left__x-large">
				<div className="left-aligned-content__background" style={{backgroundImage: "url('images/papegaai.jpg')"}}></div>
					<div className="content-width center p-left__x-large p-bottom__xx-large">
					<h2 className="creme-text p-top__xx-large p-bottom__large m-bottom__none width-cap--700 line-height__small text-shadow">
						{lang['Our worldwide network']}
					</h2>
					<Button link='/contact'>{en['Contact us']}</Button>
				</div>
			</Section>
			<Section extraClasses="section__content content-width center p-bottom__large mobile-p-bottom__large">
				<Row extraClasses="m-top__xx-large mobile-p-left__x-large">
					<Column  extraClasses="size__three-of-eight p-right__x-large">
						<SummaryTile
								titleKey="Our clients are"
								subTitleKey="Zoos &amp; private breeding centers"
								textKey="ourclientsare_text"
								buttonTextKey="readmore"
								activeLang={activeLang}
								buttonLink ={'/register#be_user'}
						/>
					</Column>
					<Column extraClasses="size__five-of-eight">
						<SummaryTile
								titleKey="Our projects"
								subTitleKey="ourprojects_subtitle_home"
								buttonTextKey="readmore"
								activeLang={activeLang}
								buttonLink="/aboutus#ourprojects"
						>
							<hr className="creme-divider" />
							<div className="row m-top__large mobile-layout--hor-scroll">
								{renderedProjects}
							</div>
							<hr className="creme-divider m-top__large m-bottom__large" />
						</SummaryTile>
					</Column>
				</Row>
			</Section>
		</React.Fragment>
	)
}

export default Home
